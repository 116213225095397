<template>
    <div ref="refDialogReplyAppt">
        <el-dialog
            :visible.sync="visible"
            :append-to-body="true"
            :destroy-on-close="true"
            class="zimbra-dialog zm-calendar zimbra-wrap"
            :modal="true"
            :modal-append-to-body="true"
            :before-close="handleCancel"
        >
        <div class="wrap zm-calendar-form">
            <div class="wrap-pri">
                 <div class="main-content-wrapper main-calendar">
                    <div class="header-pri">
                        <div class="head-pri">
                            <span>{{ title ? title : $t("zimbra.zhMsg.compose") }}</span>
                        </div>
                        <button @click="onCloseLarge()" class="btn-close">
                            <i class="fal fa-times"></i>
                        </button>
                    </div>
                    <div class="main-calendar-pri d-block p-3 scrollbar-y">
                        <MailComposeForm
                            ref="mailComposeForm"
                            :data="dataCompose"
                            @sendMsgSuccess="sendMsgSuccess"
                        />
                    </div>
                 </div>
            </div>
        </div>
        </el-dialog>
    </div>
</template>
<script>
import MailComposeForm from "@/components/modules/email/mail-compose/MailComposeForm";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import { FOLDER_DEFAULT } from "@/utils/constants";
export default {
    data() {
        return {
            visible: false,
            dataCompose: {},
            title: ""
        }
    },
    props: {
    },
    components: {
        MailComposeForm
    },
    computed: {
    },
    methods: {
        async handleCancel() {
            this.visible = false
        },
        show(_dataCompose, _title) {
            this.visible = true;
            this.dataCompose = _dataCompose
            this.title = _title
        },
        async onCloseLarge() {
            await this.$refs.mailComposeForm.executeSaveDraft()
            // const data = this.$refs.mailComposeForm.getTabData()
            // if (data && data.draftId && data.isSaveAuto) {
            //     await this.$confirm(
            //         this.$t("zimbra.zmMsg.askSaveAutosavedDraft"),
            //         this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
            //         {
            //             distinguishCancelAndClose: true,
            //             confirmButtonText: this.$t("zimbra.zhMsg.yes"),
            //             cancelButtonText: this.$t("zimbra.zhMsg.no"),
            //             type: "warning",
            //         }
            //     ).then(async () => {
            //         // lưu nháp
            //         await this.$refs.mailComposeForm.executeSaveDraft()
            //     }).catch(async (action) => {
            //         if (action === "cancel") {
            //             const formData = {
            //                 id: `${data.draftId}`,
            //                 op: "delete",
            //             };
            //             await ZimbraMailService.msgActionRequest(formData) // fix bug load lại folder trước khi xóa nháp
            //             // reload Folder thư nháp
            //             this.$root.$emit(
            //                 "reloadDataFolder",
            //                 FOLDER_DEFAULT.DRAFTS
            //             );
            //         } else {
            //             return;
            //         }
            //     })
            // } else {
            //     await this.$confirm(
            //     this.$t("zimbra.zmMsg.askSaveDraft"),
            //     this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
            //     {
            //         distinguishCancelAndClose: true,
            //         confirmButtonText: this.$t("zimbra.zhMsg.yes"),
            //         cancelButtonText: this.$t("zimbra.zhMsg.no"),
            //         type: "warning",
            //     }
            // )
            //     .then(async () => {
            //         await this.$refs.mailComposeForm.executeSaveDraft()
            //     })
            //     .catch(() => {})
            // }
            // this.visible = false
            // this.$emit("onCloseDialog")
        },
        /**
         * hàm call back khi gửi tin thành công
         */
        sendMsgSuccess() {
            this.visible = false;
            this.$emit("sendSuccess")
        },
        showCreateNewMsg(data) {
            this.dataCompose = data;
            this.visible = true;
        }
    }
};
</script>
<style scoped>
.main-calendar-pri {
    display: block;
}
</style>
